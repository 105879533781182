import { marked } from "marked"
import DOMPurify from "dompurify"

marked.setOptions({
  headerIds: false,
  xhtml: true,
  smartLists: true,
  gfm: true,
  breaks: true,
})

if (DOMPurify.isSupported) {
  DOMPurify.setConfig({
    USE_PROFILES: {
      html: true,
    },
    ADD_ATTR: ["target", "align", "style", "class", "data-full-src"],
    ADD_TAGS: ["img", "div", "script"],
  })
}

const link = (href: string, title: string, text: string) => {
  const titleAttr = title ? ` title="${title}"` : ""
  return `<a class="text-link" href="${href}"${titleAttr} rel="noopener nofollow" target="_blank">${text}</a>`
}

const fullRenderer = new marked.Renderer()
fullRenderer.image = (href, title, alt) => {
  const titleAttr = title ? ` title="${title}"` : ""
  return `<img src="${href}" alt="${alt}"${titleAttr} class="resizable-image" data-full-src="${href}">`
}
fullRenderer.link = link
fullRenderer.html = (html) => html

const plainTextRenderer = new marked.Renderer()
plainTextRenderer.link = (_href, _title, text) => text
plainTextRenderer.image = (_href, _title, alt) => `[Image: ${alt}]`
plainTextRenderer.br = () => " "
plainTextRenderer.strong = (text) => text
plainTextRenderer.list = (body) => body
plainTextRenderer.listitem = (text) => `${text} `
plainTextRenderer.heading = (text) => text
plainTextRenderer.paragraph = (text) => ` ${text} `
plainTextRenderer.code = (code) => code
plainTextRenderer.codespan = (code) => code
plainTextRenderer.html = (html) => html
plainTextRenderer.del = (text) => text

const sanitize = (input: string) => (DOMPurify.isSupported ? DOMPurify.sanitize(input) : input)

const imageStyle = `
<style>
  .resizable-image {
    width: 70%;
    cursor: pointer;
    transition: width 0.3s ease;
  }
  .resizable-image.fullsize {
    width: auto;
    max-width: 100%;
  }
</style>
`

const imageScript = `
<script>
  document.addEventListener('DOMContentLoaded', function() {
    document.body.addEventListener('click', function(e) {
      if (e.target && e.target.classList.contains('resizable-image')) {
        e.target.classList.toggle('fullsize');
      }
    });
  });
</script>
`

export const full = (input: string): string => {
  const renderedContent = sanitize(marked(input, { renderer: fullRenderer })).trim()
  return `${imageStyle}${renderedContent}${imageScript}`
}

export const plainText = (input: string): string => {
  return sanitize(marked(input, { renderer: plainTextRenderer })).trim()
}